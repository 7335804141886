<template>
  <the-new-header>
    <template v-slot:main-block>
      <div class="profile-settings-header">
        {{ $route.meta?.header }}
      </div>
    </template>
  </the-new-header>
  <div class="page-content profile">
    <slot name="sidebar"></slot>
    <main class="profile-settings-wrap-new">
      <div class="account-tabs" v-if="$route.meta?.showTabs">
        <router-link :class="{'active': $route.name === 'Profile'}"
                     :to="{name: 'Profile'}">
          My Account
        </router-link>
        <router-link :class="{'active': $route.name === 'NotificationsList'}"
                     :to="{name: 'NotificationsList'}">
          Reports
        </router-link>
        <router-link :class="{'active': $route.name === 'IntegrationSettingsList'}"
                     :to="{name: 'IntegrationSettingsList'}">
          Integrations
        </router-link>
<!--        <router-link :class="{'active': $route.name === 'Settings'}" :to="{name: 'Settings'}">-->
<!--          Settings-->
<!--        </router-link>-->
      </div>
      <router-view v-slot="slotProps">
        <component :is="slotProps.Component"></component>
      </router-view>
    </main>
  </div>
</template>
<script>
import TheNewHeader from "@/components/TheNewHeader/index.vue";

export default {
  name: 'Account',
  components: {
    TheNewHeader
  },
}
</script>
<style src="./styles.scss" lang="scss"></style>
